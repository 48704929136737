import React, { useContext, Fragment } from 'react'
import { Link } from 'gatsby'
import LocaleContext from '../../context/LocaleContext'
import PageContext from '../../context/PageContext'
import { Locale, localisePath } from '../../domain/locale'
import { Popover, Transition } from '@headlessui/react'
import { ChevronDownIcon, GlobeIcon } from '@heroicons/react/solid'
import ReactCountryFlag from 'react-country-flag'

type LocaleSelectItemProps = {
    locale: Locale
}

function LocaleSelectItem({ locale }: LocaleSelectItemProps) {
    const wrapperClass =
        'flex flex-row justify-center space-x-2 -m-3 p-3 rounded-md hover:bg-gray-50 transition ease-in-out duration-150'

    if (locale !== Locale.Global) {
        // Determine country code
        let countryCode: string
        if (locale === Locale.ID) {
            countryCode = 'ID'
        } else if (locale === Locale.SG) {
            countryCode = 'SG'
        } else if (locale === Locale.AU) {
            countryCode = 'AU'
        } else {
            countryCode = 'ID'
        }

        return (
            <span className={wrapperClass}>
                <ReactCountryFlag
                    countryCode={countryCode}
                    style={{ width: '1.5em', height: '1.5em' }}
                    svg
                />
                <p className="text-md font-medium">{countryCode}</p>
            </span>
        )
    } else {
        return (
            <span className={wrapperClass}>
                <GlobeIcon className="w-6 h-6 text-gray-700" />
                <p className="text-md font-medium">Global</p>
            </span>
        )
    }
}

function LocaleSelect() {
    const { basepath, localised } = useContext(PageContext)
    const { locale: selectedLocale, setLocale } = useContext(LocaleContext)

    const availableLocales = [Locale.ID, Locale.SG, Locale.AU, Locale.Global]

    return (
        <Popover className="relative">
            {() => (
                <>
                    <Popover.Button className="flex flex-row space-x-2">
                        <LocaleSelectItem locale={selectedLocale} />
                        <ChevronDownIcon className="w-6 h-6" />
                    </Popover.Button>

                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                    >
                        <Popover.Panel className="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen sm:w-40 max-w-xs sm:px-0">
                            {({ close }) => (
                                <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                                    <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                                        {availableLocales.map(locale =>
                                            !!localised ? (
                                                <Link
                                                    key={locale}
                                                    to={localisePath(
                                                        basepath,
                                                        locale
                                                    )}
                                                    onClick={() => {
                                                        close()
                                                    }}
                                                >
                                                    <LocaleSelectItem
                                                        locale={locale}
                                                    />
                                                </Link>
                                            ) : (
                                                <button
                                                    key={locale}
                                                    onClick={() => {
                                                        setLocale(locale)
                                                        close()
                                                    }}
                                                >
                                                    <LocaleSelectItem
                                                        locale={locale}
                                                    />
                                                </button>
                                            )
                                        )}
                                    </div>
                                </div>
                            )}
                        </Popover.Panel>
                    </Transition>
                </>
            )}
        </Popover>
    )
}

export default LocaleSelect
