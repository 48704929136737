import React from 'react'
import { Locale } from '../domain/locale'
import { IPageContext } from '../domain/page'
import { INDEX_PATH } from '../domain/path'

const ctx: IPageContext = {
    basepath: INDEX_PATH,
    locale: Locale.Global,
    localised: true,
}

const PageContext = React.createContext(ctx)

export default PageContext
