import React, { useContext, useEffect } from 'react'
import { useLocation } from '@reach/router'
import LocaleContext from '../../context/LocaleContext'
import PageContext from '../../context/PageContext'
import { Locale } from '../../domain/locale'
import { BLOG_PATH, INDEX_PATH } from '../../domain/path'
import Header from './Header'
import Footer from './Footer'

interface LayoutProps {
    children: React.ReactNode | React.ReactNode[]
}

const ipLookupUrl = `https://api.ipstack.com/check?access_key=${process.env.GATSBY_IPSTACK_ACCESS_KEY}&fields=country_code`

function Layout({ children }: LayoutProps) {
    const { setLocale } = useContext(LocaleContext)
    const { basepath } = useContext(PageContext)
    const { pathname } = useLocation()

    // Check locale during first load
    useEffect(() => {
        async function checkLocale() {
            // Make API call to check country code
            const response = await fetch(ipLookupUrl).then(res => res.json())

            // If we get bad response, forced to stay at the current locale
            if (!response.country_code) {
                return
            }

            // For a valid response, extract the country code
            const countryCode = response.country_code

            // If country code is equal to either ID, SG+MY or AU+NZ (check each case),
            // set the appropriate locale. Otherwise, set to Global
            if (countryCode === 'AU' || countryCode === 'NZ') {
                setLocale(Locale.AU)
            } else if (countryCode === 'SG' || countryCode === 'MY') {
                setLocale(Locale.SG)
            } else if (countryCode === 'ID') {
                setLocale(Locale.ID)
            } else {
                setLocale(Locale.Global)
            }
        }

        // If the page we're visiting is non-localised (e.g. blog),
        // we perform country lookup to hydrate LocaleContext
        if (!!pathname.includes(BLOG_PATH)) {
            // Do nothing for now; don't need to check
        }
    }, [])

    // Differentiate container class if the basepath of current page is INDEX_PATH
    const containerClass =
        basepath === INDEX_PATH
            ? 'py-6 md:py-12 lg:py-9'
            : 'container p-6 mx-auto md:py-12 lg:py-16 max-w-7xl'

    // Otherwise, return content as usual
    return (
        <React.Fragment>
            <Header />

            <div className={containerClass}>{children}</div>

            <Footer />
        </React.Fragment>
    )
}

export default Layout
