import React, { useState, useContext } from 'react'
import { Link } from 'gatsby'
import CheckoutContext from '../../context/CheckoutContext'
import PageContext from '../../context/PageContext'
import { localisePath } from '../../domain/locale'
import {
    INDEX_PATH,
    PRODUCTS_PATH,
    CONTACT_US_PATH,
    CART_PATH,
    FAQ_PATH,
} from '../../domain/path'
import { ShoppingCartIcon } from '@heroicons/react/outline'
import { MenuIcon, XIcon } from '@heroicons/react/solid'
import classnames from 'classnames'
import Logo from '../../assets/logo.svg'
import LocaleSelect from './LocaleSelect'

type Route = {
    path: string
    name: string
    localised: boolean
}

const links: Route[] = [
    { path: INDEX_PATH, name: `Home`, localised: true },
    { path: PRODUCTS_PATH, name: `Products`, localised: true },
    { path: CONTACT_US_PATH, name: `Contact Us`, localised: true },
    { path: FAQ_PATH, name: 'FAQ', localised: false },
]

type DesktopLinkProps = {
    link: Route
}

const DesktopLink = ({ link }: DesktopLinkProps) => {
    const normalClass =
        'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-t-0 text-md font-medium'
    const activeClass =
        'border-primary text-primary inline-flex items-center px-1 pt-1 border-t-0 text-md font-medium'
    return (
        <Link
            to={link.path}
            className={normalClass}
            activeClassName={activeClass}
        >
            {link.name}
        </Link>
    )
}

type MobileLinkProps = {
    link: Route
    onClick: React.MouseEventHandler
}

const MobileLink = ({ link, onClick }: MobileLinkProps) => {
    const normalClass =
        'border-transparent text-gray-500 hover:bg-gray-100 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium'
    const activeClass =
        'bg-ocean-green-100 border-ocean-green-500 text-ocean-green-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium'
    return (
        <Link
            to={link.path}
            className={normalClass}
            activeClassName={activeClass}
            onClick={onClick}
        >
            {link.name}
        </Link>
    )
}

type CartLinkProps = {
    onClick: React.MouseEventHandler
}

const CartLink = ({ onClick }: CartLinkProps) => {
    const { lineItems } = useContext(CheckoutContext)
    const { locale } = useContext(PageContext)

    const isEmpty = lineItems.length > 0
    const path = localisePath(CART_PATH, locale)

    return (
        <Link
            to={path}
            onClick={onClick}
            className="bg-white p-1 mt-2 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ocean-green-500"
        >
            <span className="sr-only">View cart</span>

            {isEmpty && (
                <span className="absolute top-0 right-0 mt-6 mr-3 md:mr-1 w-2 h-2 bg-primary rounded-full" />
            )}
            <span className="text-gray-600 hover:text-gray-700 hover:bg-gray-100">
                <ShoppingCartIcon className="w-6 h-6" />
            </span>
        </Link>
    )
}

const Header = () => {
    const [toggled, setToggled] = useState(false)
    const { locale } = useContext(PageContext)

    // Menu icon should be visible when not toggled and vice versa
    const menuIconClass = classnames('h-6 w-6', {
        block: !toggled,
        hidden: toggled,
    })
    // Close icon should be visible when toggled and vice versa
    const closeIconClass = classnames('h-6 w-6', {
        block: toggled,
        hidden: !toggled,
    })
    // Menu nav is like the close icon: visible when toggled and vice versa
    const menuNavClass = classnames('sm:hidden', {
        block: toggled,
        hidden: !toggled,
    })
    const handleMenuToggle = () => setToggled(!toggled)
    const handleCloseMenu = () => setToggled(false)

    const localisedLinks = links.map(link => ({
        ...link,
        path: link.localised ? localisePath(link.path, locale) : link.path,
    }))

    return (
        <nav className="bg-white shadow">
            <div className="max-w-7xl mx-auto px-3 sm:px-6 lg:px-8">
                <div className="relative flex justify-between h-16">
                    <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                        {/*<!-- Mobile menu button -->*/}
                        <button
                            className="inline-flex items-center justify-center p-2 rounded-md text-gray-600 hover:text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-ocean-green-500"
                            aria-expanded="false"
                            onClick={handleMenuToggle}
                        >
                            {/* Menu icon */}
                            <span className="sr-only">Open main menu</span>
                            <MenuIcon className={menuIconClass} />

                            {/* Close icon */}
                            <XIcon className={closeIconClass} />
                        </button>
                    </div>

                    {/* Telobag logo */}
                    <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                        <div className="flex-shrink-0 flex items-center">
                            <Link
                                to={localisePath(INDEX_PATH, locale)}
                                onClick={handleCloseMenu}
                            >
                                <Logo className="block h-8 w-auto" />
                            </Link>
                        </div>
                        <div className="hidden sm:ml-10 sm:flex sm:space-x-8">
                            {localisedLinks.map(link => (
                                <DesktopLink key={link.path} link={link} />
                            ))}
                        </div>
                    </div>

                    {/* Cart icon */}
                    <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                        <div className="hidden sm:flex mr-6 border-transparent hover:border-gray-300 px-1 pt-1 border-t-0">
                            <LocaleSelect />
                        </div>
                        <CartLink onClick={handleCloseMenu} />
                    </div>
                </div>
            </div>

            {/*<!--
              Mobile menu, toggle classes based on menu state.

              Menu open: "block", Menu closed: "hidden"
            -->*/}
            <div className={menuNavClass}>
                <div className="pt-2 pb-4 space-y-1 justify-center">
                    {localisedLinks.map(link => (
                        <MobileLink
                            key={link.path}
                            link={link}
                            onClick={handleMenuToggle}
                        />
                    ))}
                    <div className="border-transparent text-gray-500 hover:bg-gray-100 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium">
                        <LocaleSelect />
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default Header
