import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql, withPrefix } from 'gatsby'

type Props = {
    title: string
    titleCompany: string
    description?: string
    relativeUrl?: string
    image?: Image
}

type Image = {
    src: string
    height: number
    width: number
}

function Seo({ title, titleCompany, description, relativeUrl, image }: Props) {
    const data = useStaticQuery(graphql`
        query SiteInfoQuery {
            site {
                siteMetadata {
                    title
                    description
                    keywords
                    siteUrl
                }
            }
        }
    `)

    const defaults = data.site.siteMetadata
    const defaultImage: Image = {
        src: 'https://media.graphcms.com/output=format:jpg/OuFsqNWuTEOcLHn8Ap94?_ga=2.77798032.565932336.1630856587-817870891.1625389312',
        height: 365,
        width: 1280,
    }

    const pageTitle = `${title} - ${titleCompany}`
    const metaDescription = description || defaults.description
    const metaKeywords = defaults.keywords.join(',')
    const metaImage = image || defaultImage
    const metaUrl = relativeUrl
        ? `${defaults.siteUrl}${relativeUrl}`
        : defaults.siteUrl

    const ogTitle = `${title} | ${titleCompany}`

    return (
        <Helmet htmlAttributes={{ lang: 'en' }}>
            <title>{pageTitle}</title>
            <link rel="canonical" href={metaUrl} />
            <meta name="description" content={metaDescription} />
            <meta name="keywords" content={metaKeywords} />

            <meta property="og:type" content="website" />
            <meta property="og:title" content={ogTitle} />
            <meta property="og:description" content={metaDescription} />
            <meta property="og:url" content={metaUrl} />
            {image && (
                <meta property="og:image" content={withPrefix(metaImage.src)} />
            )}
            {image && (
                <meta
                    property="og:image:height"
                    content={metaImage.height.toString()}
                />
            )}
            {image && (
                <meta
                    property="og:image:width"
                    content={metaImage.width.toString()}
                />
            )}

            <meta property="twitter:title" content={title} />
            <meta property="twitter:description" content={metaDescription} />
            {image ? (
                <meta property="twitter:card" content="summary_large_image" />
            ) : (
                <meta property="twitter:card" content="summary" />
            )}
        </Helmet>
    )
}

export default Seo
